@import '~antd/lib/style/themes/default.less';

.popover {
  width: 336px;
}

.noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
}

.icon {
  padding: 4px;
}

.tabs {
  :global {
    .ant-tabs-nav-scroll {
      text-align: center;
    }
    .ant-tabs-bar {
      margin-bottom: 4px;
    }
  }
}

@primary-color: #48c75b;